import React from 'react'

const ShowRollWrapper = ({ title, children }) => (
  <section
    className='c-show-roll'
  >
    <div
      className='o-container'
    >
      <h3
        className='c-show-roll__title'
      >
        {title}
      </h3>
      {children}
    </div>
  </section>
)

export default ShowRollWrapper
