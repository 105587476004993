import React from 'react'
import PropTypes from 'prop-types'

import View from './ShowRollView'
import ExpandableView from './ShowRollExpandableView'

const ShowRoll = ({ shows, split, title }) => (
  split
    ? <ExpandableView
      shows={shows}
      split={split}
      title={title}
    />
    : <View
      shows={shows}
      title={title}
    />
)

ShowRoll.propTypes = {
  shows: PropTypes.array.isRequired,
  split: PropTypes.number,
  title: PropTypes.string
}

ShowRoll.defaultProps = {
  title: `Our Shows`
}

export default ShowRoll
