import React from 'react'
import PropTypes from 'prop-types'
import Collapsible from 'react-collapsible'

import ShowRollLoop from './ShowRollLoop'
import ShowRollWrapper from './ShowRollWrapper'

const TriggerButton = ({ classNames }) => (
  <button className={classNames}>+ View all shows</button>
)

const ShowRollExpandableView = ({ shows, split, title }) => {
  // Split the shows in to 2 arrays
  const visibleShows = shows.slice(0, split)
  const hiddenShows = shows.slice(split)

  return (
    <ShowRollWrapper
      title={title}
    >

      {/* We want to show some shows on load... */}
      <div
        className='c-show-roll-grid'
      >
        <ShowRollLoop
          shows={visibleShows}
          lazyload
        />
      </div>

      <Collapsible
        trigger={
          <TriggerButton classNames={`c-btn c-show-roll__btn`} />
        }
        triggerWhenOpen={
          <TriggerButton classNames={`c-btn u-hide-visually`} />
        }
      >

        {/* ...and hide some behind a Collapsible. */}
        <div
          className='c-show-roll-grid'
        >
          <ShowRollLoop
            shows={hiddenShows}
            lazyload
          />
        </div>
      </Collapsible>
    </ShowRollWrapper>
  )
}

ShowRollExpandableView.propTypes = {
  shows: PropTypes.array.isRequired,
  split: PropTypes.number
}

export default ShowRollExpandableView
