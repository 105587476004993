import React from 'react'
import PropTypes from 'prop-types'

import ShowRollWrapper from './ShowRollWrapper'
import ShowRollLoop from './ShowRollLoop'

const ShowRollView = ({ shows, title }) => (
  <ShowRollWrapper
    title={title}
  >
    <div
      className='c-show-roll-grid'
    >
      <ShowRollLoop
        shows={shows}
        lazyload={false}
      />
    </div>
  </ShowRollWrapper>
)

ShowRollView.propTypes = {
  shows: PropTypes.array.isRequired
}

export default ShowRollView
