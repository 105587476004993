import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import sanitizer from 'utilities/sanitizer'

import Layout from 'Layout'
import SEO from 'SEO'
import ShowRoll from 'ShowRoll'

const ShowsIndexPage = ({ data }) => (
  <Layout>
    <SEO
      title='Shows'
    />
    <StaticQuery
      query={AllShowsQuery}
      render={data => {
        const { allMarkdownRemark } = data
        const { edges: showsEdges } = allMarkdownRemark

        return (
          <ShowRoll shows={sanitizer(showsEdges)} lazyload={false} />
        )
      }}
    />
  </Layout>
)

ShowsIndexPage.propTypes = {
  data: PropTypes.object.isRequired
}

const AllShowsQuery = graphql`
  query AllShowsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "templates/ShowTemplate" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            artwork
          }
        }
      }
    }
  }
`

export default ShowsIndexPage
